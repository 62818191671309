<template>
  <ModalFull
    :is-visible="isVisible"
    :reset-container="true"
    container-class="w-full max-w-3xl z-maximum"
  >
    <div class="w-full relative bg-transparent" v-if="isVisible">
      <button
        @click="$emit('close')"
        class="absolute -right-4 -top-4 text-white bg-black rounded-full bg-opacity-70 w-8 h-8 flex items-center justify-center"
      >
        <Icon name="close" class="w-6 h-6" />
      </button>
      <iframe
        class="w-full aspect-video"
        :src="`${videoId}`"
        id="video"
        allowscriptaccess="always"
        allow="autoplay"
      ></iframe>
    </div>
  </ModalFull>
</template>
<script>
export default {
  name: "VimeoVideoPopup",
  props: {
    isVisible: Boolean,
    videoId: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
};
</script>

<style lang="scss">
.aspect-video {
  aspect-ratio: 16/9;
}
.z-maximum {
  z-index: 999999;
}
</style>
