<template>
  <div class="explore-cards">
    <div class="cat-card">
      <h3>Explore Categories</h3>
      <div class="cats">
        <div class="info">
          <p>Check out our extensive assortment of top products</p>
          <router-link
            :to="'/categories'"
            class="flex items-center uppercase text-sm"
          >
            View All
            <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
          </router-link>
        </div>
        <div class="image">
          <img src="/images/explore-categories.png" alt="Explore Categories" />
        </div>
      </div>
    </div>
    <Card title="Top Brands">
      <template #action>
        <router-link
          :to="'/brands'"
          class="flex items-center uppercase text-sm"
        >
          View All
          <Icon class="w-5 h-5 -my-2 ml-1" name="right-arrow" />
        </router-link>
      </template>

      <div class="top-brands">
        <router-link :to="b.link" v-for="b in brands" :key="b.img">
          <img :src="b.img" :alt="b.name" />
        </router-link>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: "ExploreCards",
  setup() {
    const brands = [
      {
        name: "MJ Arsenal",
        img: "/images/top-brands/mj-arsenal.png",
        link: "/brand/mj-arsenal",
      },
      {
        name: "Clipper",
        img: "/images/top-brands/clipper.png",
        link: "/brand/clipper",
      },
      {
        name: "Cartisan",
        img: "/images/top-brands/cartisan.png",
        link: "/brand/cartisan",
      },
      {
        name: "PAX",
        img: "/images/top-brands/cell.png",
        link: "/brand/ccell",
      },
      {
        name: "Grav",
        img: "/images/top-brands/grav.png",
        link: "/brand/grav",
      },
      {
        name: "PAX",
        img: "/images/top-brands/pax.png",
        link: "/brand/pax",
      },
      {
        name: "Puffco",
        img: "/images/top-brands/puffco.png",
        link: "/brand/puffco",
      },
      {
        name: "Raw",
        img: "/images/top-brands/raw.png",
        link: "/brand/raw",
      },
      {
        name: "Storz",
        img: "/images/top-brands/storz.png",
        link: "/brand/storz-bickel",
      },
    ];

    return {
      brands,
    };
  },
};
</script>

<style lang="scss" scoped>
.explore-cards {
  @apply grid grid-cols-1 xl:grid-cols-2 gap-5 md:gap-6;

  .cat-card {
    background: #7180b9;
    @apply flex flex-col space-y-5 w-full rounded-md shadow-box px-8 pt-6 pb-10 text-white;

    h3 {
      @apply text-xl text-white font-semibold;
    }

    .cats {
      @apply flex flex-col-reverse md:flex-row items-center md:items-end gap-4;

      .info {
        @apply w-full flex flex-col items-center md:items-start md:w-1/2 pb-2;

        p {
          @apply opacity-60 text-base mb-2;
        }
      }

      .image {
        @apply flex-grow flex items-center justify-end;
      }
    }
  }
  .top-brands {
    @apply grid grid-cols-3 md:grid-cols-4 gap-8;

    a {
      @apply flex items-center justify-center;

      img {
        max-height: 70px;
        max-width: 115px;
      }

      &:last-child {
        @apply md:hidden;
      }
    }
  }
}
</style>
