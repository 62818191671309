<template>
  <div class="empty-item">
    <div class="empty-image" :class="type" />
    <p></p>
    <span v-if="type === ''"></span>
  </div>
</template>

<script>
export default {
  name: "ProductLoader",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.empty-item {
  @apply flex flex-col items-center space-y-2.5 leading-none text-center;

  .empty-image {
    @apply flex items-center justify-center bg-gray-100 animate-pulse w-24 rounded-lg;
    height: 110px;

    &.category {
      @apply rounded-full w-28 h-28;
    }

    &.brand {
      @apply w-36 h-28;
    }
  }
  p {
    @apply h-3 font-medium rounded bg-gray-100 animate-pulse text-opacity-0 w-36;
  }
  span {
    @apply h-2.5 font-medium rounded bg-gray-100 animate-pulse text-opacity-0 w-24;
  }
}
</style>
