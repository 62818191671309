<template>
  <Card class="trending-card">
    <router-link
      to="/trending"
      class="inner"
      :style="
        `background-image: url('/images/trending-card/${getBgImage}.svg')`
      "
    >
      <div>
        <h3 v-if="isLoggedIn">Trending in <br />{{ title || "your area" }}</h3>
        <h3 v-else class="flex items-start justify-between space-x-2">
          <span>Discover the <br />Top Products</span>
          <Icon
            name="trend"
            class="xl:w-20 xl:h-14 w-10 h-10 -mt-2 -mr-5 xl:-mr-2"
          />
        </h3>
        <span class="cta"
          >Browse <Icon name="right-arrow" class="w-5 h-5 -my-2 ml-1"
        /></span>
      </div>
    </router-link>
  </Card>
</template>

<script>
import { computed } from "vue";
export default {
  name: "TrandingCard",
  props: {
    title: String,
    place: String,
    isLoggedIn: Boolean,
  },
  setup(props) {
    const availableImages = [
      "alabama",
      "alaska",
      "arizona",
      "arkansas",
      "california",
      "colorado",
      "connecticut",
      "default",
      "delaware",
      "florida",
      "georgia",
      "hawaii",
      "idaho",
      "illinois",
      "indiana",
      "iowa",
      "kansas",
      "kentucky",
      "louisiana",
      "maine",
      "maryland",
      "massachusetts",
      "michigan",
      "minnesota",
      "mississippi",
      "missouri",
      "montana",
      "nebraska",
      "nevada",
      "newhampshire",
      "newjersey",
      "newmexico",
      "newyork",
      "northcarolina",
      "northdakota",
      "ohio",
      "oklahoma",
      "oregon",
      "pennsylvania",
      "rhodeisland",
      "southcarolina",
      "southdakota",
      "tennessee",
      "texas",
      "utah",
      "vermont",
      "virginia",
      "washington",
      "westvirginia",
      "wisconsin",
      "wyoming",
    ];

    const availableImagesCodes = {
      AL: "alabama",
      AK: "alaska",
      AZ: "arizona",
      AR: "arkansas",
      CA: "california",
      CO: "colorado",
      CT: "connecticut",
      DE: "delaware",
      FL: "florida",
      GA: "georgia",
      HI: "hawaii",
      ID: "idaho",
      IL: "illinois",
      IN: "indiana",
      IA: "iowa",
      KS: "kansas",
      KY: "kentucky",
      LA: "louisiana",
      ME: "maine",
      MD: "maryland",
      MA: "massachusetts",
      MI: "michigan",
      MN: "minnesota",
      MS: "mississippi",
      MO: "missouri",
      MT: "montana",
      NE: "nebraska",
      NV: "nevada",
      NH: "newhampshire",
      NJ: "newjersey",
      NM: "newmexico",
      NY: "newyork",
      NC: "northcarolina",
      ND: "northdakota",
      OH: "ohio",
      OK: "oklahoma",
      OR: "oregon",
      PA: "pennsylvania",
      RI: "rhodeisland",
      SC: "southcarolina",
      SD: "southdakota",
      TN: "tennessee",
      TX: "texas",
      UT: "utah",
      VT: "vermont",
      VA: "virginia",
      WA: "washington",
      WV: "westvirginia",
      WI: "wisconsin",
      WY: "wyoming",
    };

    const getBgImage = computed(() => {
      let place = props.place.replace(" ", "").toLowerCase();
      if (place.length < 3) {
        place = availableImagesCodes[place.toUpperCase()];
      }
      return availableImages.includes(place) && props.isLoggedIn
        ? place
        : "default";
    });

    return {
      getBgImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.trending-card {
  @apply flex items-stretch h-full p-0 rounded-md overflow-hidden;
  background: #eee5db;

  &.fixed-aspect-ratio {
    aspect-ratio: 364/230;
    height: min-content;
  }

  a[style*="default.svg"] {
    background-position: bottom right;
    background-size: 70%;
    @media (min-width: 1680px) {
      background-position: bottom center;
    }

    @media (max-width: 1079px) {
      background-position: bottom center;
    }
  }

  .inner {
    @apply flex flex-col justify-between text-white px-6 xl:px-8 py-4 xl:py-6 h-full relative bg-cover bg-right-bottom bg-no-repeat;
  }

  h3 {
    @apply text-2xl font-semibold mb-3;
    color: #3b3b3b;
  }

  p {
    @apply text-lg md:text-2xl font-semibold text-opacity-30;
    color: #3b3b3b;
  }

  .cta {
    @apply flex items-center font-medium text-sm uppercase;
    color: #3b3b3b;
  }
}
</style>
