<template>
  <div class="flow-root">
    <ul role="list" class="-mb-8">
      <li v-for="(feat, idx) in features" :key="feat.title">
        <div class="relative pb-8">
          <span
            v-if="idx !== features.length - 1"
            class="line"
            aria-hidden="true"
          />
          <div class="relative flex items-center space-x-4">
            <div>
              <span class="icon">
                <Icon :name="feat.icon" aria-hidden="true" />
              </span>
            </div>
            <div class="min-w-0 flex-1 flex flex-col space-y-0.5">
              <p class="text-base text-gray-800">{{ feat.title }}</p>
              <span class="text-md text-theme-body">{{ feat.desc }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "GetStarted",
  setup() {
    const features = [
      {
        icon: "g-document",
        title: "Register for an Account",
        desc: "No purchase required.",
      },
      {
        icon: "g-checkbox",
        title: "Get Approved",
        desc: "Super quick, we promise.",
      },
      {
        icon: "g-package",
        title: "Start Ordering",
        desc: "From over 10,000 products.",
      },
    ];
    return {
      features,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  @apply h-12 w-12 rounded-full flex items-center justify-center;
  background: #f3e8ff;

  svg {
    @apply w-6 h-6;
  }
}

.line {
  @apply absolute top-6 left-6 -ml-px h-full w-0.5;
  background: #414a690f;
}
</style>
