<template>
  <Card class="image-card">
    <router-link
      :to="url"
      class="inner"
      :style="bgImage ? `background-image: url('${bgImage}')` : ''"
    >
      <img class="logo" v-if="logo" :src="logo" :alt="title" />
      <h3 v-if="title">{{ title }}</h3>
      <p v-if="text">{{ text }}</p>
      <span v-if="cta"
        >{{ cta }} <Icon name="right-arrow" class="w-5 h-5 -my-2 ml-1"
      /></span>
      <template v-if="videoId">
        <button class="play-icon" @click.prevent="showVideo = true">
          <svg
            class="-mr-0.5 w-5 h-5 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="currentColor"
              d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
            />
          </svg>
        </button>
        <VimeoVideo
          @close="showVideo = false"
          :isVisible="showVideo"
          :videoId="videoId"
        />
      </template>
    </router-link>
  </Card>
</template>

<script>
import VimeoVideo from "@/components/VimeoVideo.vue";

export default {
  name: "ImageCard",
  components: { VimeoVideo },
  props: {
    title: String,
    logo: String,
    text: String,
    bgImage: String,
    url: String,
    cta: String,
    videoId: String,
  },
  data() {
    return {
      showVideo: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-card {
  @apply flex items-stretch p-0 bg-black h-full;

  &.fixed-aspect-ratio {
    aspect-ratio: 364/230;
    height: min-content;
  }

  .inner {
    @apply flex flex-col max-w-full justify-center items-start text-white px-6 md:px-8 py-6 h-full bg-cover bg-center bg-no-repeat rounded-md overflow-hidden relative;

    &::before {
      @apply absolute inset-0 opacity-50;
      content: "";
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.2) 39.09%,
        rgba(0, 0, 0, 0) 68.2%
      );
    }
  }

  &.align-top {
    .inner {
      @apply justify-start;
    }
  }
  .logo {
    @apply inline-block mb-3 max-h-9 relative;
  }

  h3 {
    @apply text-xl font-semibold z-50 text-white relative;
  }

  p {
    @apply text-base font-medium text-white z-50 text-opacity-70 relative;
  }

  span {
    @apply flex items-center font-medium text-sm text-white uppercase z-50 mt-3 relative;
  }

  .play-icon {
    @apply inline-flex justify-center items-center bg-white w-12 h-12 rounded-full text-black focus:outline-none absolute right-8 bottom-8;

    svg {
      @apply w-6 h-6;
    }
  }
}
</style>
