import { inject, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";
import Segments from "./Segments";
const { ProductListViewed, ProductListViewedNonLoggedIn } = Segments();

export default function Collections(limit = 0) {
  const axios = inject("axios");

  const collection = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
  });

  const getCollections = () => {
    collection.loading = true;
    axios.api
      .get("/collections")
      .then((resp) => {
        if (resp.status) {
          collection.data = resp.data.data;
          collection.limitData = resp.data.data.slice(0, limit);
        } else {
          collection.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (collection.loading = false));
  };

  const collectionData = reactive({
    loading: false,
    data: [],
    // limitData: [],
    error: "",
    loadingScroll: false,
  });

  const route = useRoute();

  var page = 1;
  let lastPage = 0;
  let sort = "latest";
  let stock = null;
  let load = false;

  const getCollectionDataNewArrival = async () => {
    collectionData.loading = true;
    load = true;
    if (axios.isLoggedIn) {
      axios.authApi
        .get("/aCollection?collection_slug=new-arrivals&page=1")
        .then((resp) => {
          if (resp.status) {
            collectionData.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;
            
            // Fire Product List Viewed Event Segment for logged in user
            ProductListViewed(route, collectionData.data?.data?.slice(0,10), 'new-arrivals', 1, 'home');
          } else {
            collectionData.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionData.loading = false));
    } else {
      axios.api
      .get("/collection?collection_slug=new-arrivals", {
        params: {
          page: page,
        },
      })
      .then((resp) => {
        if (resp.status) {
          collectionData.data = resp.data.data;
          lastPage = resp.data.data.last_page;
          load = false;

          // Fire Product List Viewed Event Segment for non logged in user
          ProductListViewedNonLoggedIn(route, collectionData.data?.data?.slice(0,10), 'new-arrivals', 1, 'home');
        } else {
          collectionData.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (collectionData.loading = false));
    }
  };

  const collectionstaff = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
    loadingScroll: false,
  });
  const getCollectionStaffPicks = async () => {
    collectionstaff.loading = true;
    load = true;
    if (axios.isLoggedIn) {
      axios.authApi
        .get("/aCollection?collection_slug=staff-picks" + "&page=" + page)
        .then((resp) => {
          if (resp.status) {
            collectionstaff.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;

            // Fire Product List Viewed Event Segment for logged in user
            ProductListViewed(route, collectionstaff.data?.data?.slice(0,3), 'staff-picks', 1, 'home');
          } else {
            collectionstaff.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionstaff.loading = false));
    } else {
      axios.api
        .get("/collection?collection_slug=staff-picks" + "&page=" + page)
        .then((resp) => {
          if (resp.status) {
            collectionstaff.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;

            // Fire Product List Viewed Event Segment for non logged in user
            ProductListViewedNonLoggedIn(route, collectionstaff.data?.data?.slice(0,3), 'staff-picks', 1, 'home');
          } else {
            collectionstaff.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionstaff.loading = false));
    }
  };
  const collectionclearance = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
    loadingScroll: false,
  });
  const getCollectionClearance = async () => {
    collectionclearance.loading = true;
    load = true;
    if (axios.isLoggedIn) {
      axios.authApi
        .get("/clearance?page=1&stock=false&min=0&max=0")
        .then((resp) => {
          if (resp.status) {
            collectionclearance.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;

            // Fire Product List Viewed Event Segment for logged in user
            ProductListViewed(route, collectionclearance.data?.data?.slice(0,3), 'clearance', 1, 'home');
          } else {
            collectionclearance.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionclearance.loading = false));
    } else {
      axios.api
        .get("/clearanceProducts?page=1&stock=false&min=0&max=0")
        .then((resp) => {
          if (resp.status) {
            collectionclearance.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;

            // Fire Product List Viewed Event Segment for non logged in user
            ProductListViewedNonLoggedIn(route, collectionclearance.data?.data?.slice(0,3), 'clearance', 1, 'home');
          } else {
            collectionclearance.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionclearance.loading = false));
    }
  };
  const getCollectionData = async () => {
    if (axios.isLoggedIn) {
      collectionData.loading = true;
      load = true;
      axios.authApi
        .get("/aCollection?collection_slug=" + route.params.slug, {
          params: {
            page: page,
            sort: sort,
            stock: inStock,
          },
        })
        .then((resp) => {
          if (resp.status) {
            collectionData.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;
          } else {
            collectionData.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionData.loading = false));
    } else {
      collectionData.loading = true;
      load = true;
      axios.api
        .get("/collection?collection_slug=" + route.params.slug, {
          params: {
            page: page,
          },
        })
        .then((resp) => {
          if (resp.status) {
            collectionData.data = resp.data.data;
            lastPage = resp.data.data.last_page;
            load = false;
          } else {
            collectionData.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (collectionData.loading = false));
    }
  };

  async function getData(load) {
    let data = {};
    collectionData[load] = true;
    if (axios.isLoggedIn) {
      await axios.authApi
        .get("/aCollection", {
          params: { collection_slug: route.params.slug, page, stock, sort },
        })
        .then((res) => {
          data = res.data.data;
        });
    } else {
      await axios.api
        .get("/collection", {
          params: { collection_slug: route.params.slug, page, stock, sort },
        })
        .then((res) => {
          data = res.data.data;
        });
    }
    collectionData[load] = false;
    return data;
  }

  const sortChange = async (value) => {
    page = 1;
    sort = value;
    collectionData.data = await getData("loading");
  };

  const stockChange = async (v) => {
    page = 1;
    stock = v;
    collectionData.data = await getData("loading");
  };

  const handleScroll = async () => {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }

    const canLoadMore = page < lastPage && !load;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;

    if (canLoadMore && shouldLoadMore && !products.isLoadingScrollData) {
      page++;
      const res = await getData(store.getters.getSelected, "loadingScroll");
      if (res) {
        collectionData.data.current_page = page;
        collectionData.data.data.push(...res.data);
      }
    }
  };
  // this will register the event when the component is mounted on the DOM
  onMounted(() => window.addEventListener("scroll", handleScroll));

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  const banner = reactive({
    loading: false,
    data: [],
    name: "",
    image: "",
    error: "",
  });

  const getBanner = async () => {
    banner.loading = true;
    axios.api
      .get("/collectionDetails?collection_slug=" + route.params.slug)
      .then((resp) => {
        if (resp.status) {
          banner.data = resp.data.data;
        } else {
          banner.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (banner.loading = false));
  };

  return {
    getData,
    collection,
    getCollections,
    collectionData,
    getCollectionData,
    sortChange,
    stockChange,
    banner,
    getBanner,
    getCollectionDataNewArrival,
    collectionstaff,
    getCollectionStaffPicks,
    collectionclearance,
    getCollectionClearance,
  };
}
