<template>
  <div class="t-slider max-container">
    <div class="t-header">
      <div class="flex items-center justify-between ">
        <h3>Trusted by our customers</h3>
        <div class="t-actions">
          <div class="arrows">
            <button @click="$refs.tslider.goToPrev()">
              <ChevronLeftIcon />
            </button>
            <button @click="$refs.tslider.goToNext()">
              <ChevronRightIcon />
            </button>
          </div>
          <div class="dots"></div>
        </div>
      </div>
    </div>
    <VueAgile class="t-contents" :options="options" ref="tslider">
      <div class="t-item" v-for="t in testimonials" :key="t">
        <div class="inner">
          <div class="info">
            <p>{{ t.title }}</p>
            <span>{{ t.text }}</span>
          </div>
          <div class="author">
            <img :src="t.img" :alt="t.name" />
            <div class="text">
              <p>{{ t.name }}</p>
              <span>{{ t.place }}</span>
            </div>
          </div>
        </div>
      </div>
    </VueAgile>
  </div>
</template>

<script>
import { ref } from "vue";
import { VueAgile } from "vue-agile";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/solid";

const options = {
  navButtons: false,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const defaultTestimonials = [
  {
    name: "Kevin Engle",
    place: "723 Vapor | Lexington, KY",
    title: "Medusa is our partner",
    img: "/images/testimonial/kevin.png",
    text:
      "Running a large volume business, we've worked with a lot of distributors. After seeing what everyone else has to offer, we decided Medusa is the best partner. They have their finger on the pulse of the market and make our job a lot easier to do.",
  },
  {
    name: "Kristin Emery",
    place: "Fog City Vapor | Bensalem, PA",
    title: "Highly recommended",
    img: "/images/testimonial/kirstin.png",
    text:
      "So glad I found Medusa, they go above and beyond to fulfill all of our vape sourcing needs. They are always available to figure out anything that comes up.  I would recommend them to anyone that is looking for a distributor.",
  },
  {
    name: "AZ Troenkrasnow",
    place: "The Mushroom | New Orleans, LA",
    title: "No more backorders!",
    img: "/images/testimonial/az.png",
    text:
      "Medusa isn't just another distributor, they are our partner as we grow. The reps make sure we have the information we need to get the best products for our store. Partnering with Medusa has increased our profits, improved our in-stock rates and allowed us to deliver the best products at the best prices to our customers.",
  },
  {
    name: "Kamren Eaton",
    place: "Alaska Elixirs | Wasilla, AK",
    title: "Awesome staff!",
    img: "/images/testimonial/kamren.png",
    text:
      "We've been doing business with Medusa for 3 years. We started working with them because of their easy-to-use website and awesome sales reps. They have been very professional and quick to answer. Any time we’ve had a problem, they quickly fix any issues. They are our main supplier due to the awesome staff and professionalism.",
  },
  {
    name: "Joel Illouz",
    place: "Voltage Vapors | Enfield, CT",
    title: "Fastest Shipping Around",
    img: "/images/testimonial/joel.png",
    text:
      "Medusa has been our main supplier for years. Their ability to stock everything we need and to stay on top of what’s new and exciting is unmatched. Their quick turnaround times are a breath of fresh air and the entire staff is extremely helpful. We can't recommend Medusa enough.",
  },
];
export default {
  name: "TestimonialSlider",
  props: {
    data: {
      type: Array,
      default: () => defaultTestimonials,
    },
  },
  components: {
    VueAgile,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  setup(props) {
    const testimonials = ref(props.data);
    return {
      testimonials,
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.t-slider {
  @apply w-full;

  .t-header {
    @apply w-full px-8 py-6 h-36;
    background: #908983;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    h3 {
      @apply text-white text-xl font-medium;
    }

    .arrows {
      @apply flex items-center space-x-1 text-white;

      svg {
        @apply w-5 h-5;
      }
    }
  }

  .t-contents {
    @apply w-auto min-w-full -mt-20 px-6 -mx-3;

    .t-item {
      @apply py-5 sm:p-5 sm:py-6 select-none h-full;
      .inner {
        @apply bg-white flex flex-col space-y-2 px-8 py-6 rounded-md h-full justify-between;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);

        .info {
          @apply flex flex-col space-y-2;

          p {
            @apply text-md text-theme-secondary font-medium;
          }

          span {
            @apply text-md text-theme-body font-light leading-normal;
          }
        }

        .author {
          @apply flex items-center space-x-3 pt-3.5;

          img {
            @apply w-10 h-10 rounded-full flex-shrink-0;
          }

          p {
            @apply text-xs font-semibold text-theme-secondary;
          }

          span {
            @apply text-xs font-normal text-theme-body;
          }
        }
      }
    }
  }
}
</style>
