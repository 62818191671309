<template>
  <div class="guest-banner" :class="{ hasbg: !!background }">
    <div class="guest-hero" :style="`background-image: url(${background})`">
      <div class="info ">
        <Icon name="lightning" class="w-5 h-5 mb-5" />
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <div class="actions">
          <button @click="$router.push('/join-us')">{{ button }}</button>
          <p class="text"><Icon name="star" /><span>Members Only</span></p>
        </div>
      </div>
      <div class="extra-info">
        <slot />
      </div>
    </div>
    <div class="items-grid">
      <div class="item">
        <div class="icon">
          <Icon name="high-quality-light" />
        </div>
        <div>
          <p>The Best Products</p>
          <span>{{ commaFormat(totalCount) }} of them and growing.</span>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <Icon name="easy-payments-light" />
        </div>
        <div>
          <p>Multiple Payment Options</p>
          <span>Pay now or pay later.</span>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <Icon name="top-brands-light" />
        </div>
        <div>
          <p>Super fast Shipping</p>
          <span>Most orders ship same day.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import Mymixin from "@/modules/mixins";

export default {
  name: "GuestBanner",
  mixins: [Mymixin],
  props: {
    title: String,
    description: String,
    button: String,
    background: String,
  },
  setup() {
    const axios = inject("axios");
    var totalCount = ref("0");
    function variantCounts() {
      axios.api
        .get("/variantsCount")
        .then((res) => (totalCount.value = res.data));
    }
    variantCounts();
    return {
      totalCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.guest-banner {
  background: #342356;
  @apply w-full rounded-t-lg overflow-hidden px-8 md:px-12;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);

  &.dark {
    background: #16131a;
  }

  .guest-hero {
    @apply grid grid-cols-1 lg:grid-cols-2 text-white text-left;

    .info {
      @apply w-full pt-10 pb-12 flex flex-col max-w-md z-10;
      h3 {
        @apply text-3xl font-semibold leading-normal text-white mb-2;
      }

      p {
        @apply opacity-70 text-base font-medium max-w-lg;
      }

      .actions {
        @apply flex items-center space-x-6 mt-6;

        button {
          @apply bg-white rounded text-black text-md font-medium px-5 py-2.5 leading-none;
        }

        .text {
          @apply flex items-center text-white text-md font-medium space-x-1 opacity-100 leading-none;

          svg {
            @apply w-4 h-4;
            color: #df99f7;
          }
        }
      }
    }
  }

  .items-grid {
    @apply grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 xl:gap-6 -mx-12 px-12 py-6 relative z-50;
    background: #16131a;
    .item {
      @apply flex items-center;
      .icon {
        @apply flex-shrink-0 w-12 h-12 mr-4;
        svg {
          @apply w-full h-full;
        }
      }
      p {
        @apply text-white text-base font-semibold;
      }
      span {
        @apply text-white opacity-40;
      }
    }
  }

  &.hasbg {
    @apply px-0;
    .guest-hero {
      @apply bg-no-repeat bg-right-bottom bg-cover 2xl:bg-contain 2xl:bg-right-top relative px-8 md:px-12;
      &::before {
        @apply absolute inset-0;
        content: "";
        @media (min-width: 1400px) {
          background: linear-gradient(
            90deg,
            #1d1d1d 14.44%,
            rgba(0, 0, 0, 0) 42.01%
          );
        }

        @media (max-width: 1399px) {
          background: linear-gradient(
            126deg,
            #1d1d1d 50%,
            rgba(0, 0, 0, 0) 70%
          );
        }
      }
    }

    .guest-hero {
      @apply grid grid-cols-1 relative;

      .info {
        @apply max-w-2xl z-40;
      }
    }

    .items-grid {
      @apply mx-0;
    }
  }
}
</style>
