<template>
  <div
    :class="[
      'row-grid-wrapper',
      { inverted: inverse },
      { 'disable-ratio': disableRatio },
      { 'two-sides': !!$slots['asideFirst'] },
    ]"
  >
    <div v-if="!!$slots['asideFirst']" class="aside-col first-aside">
      <slot name="asideFirst" />
    </div>
    <div class="extended-col">
      <slot />
    </div>
    <div class="aside-col">
      <slot name="aside" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RowGridWrapper",
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
    disableRatio: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.row-grid-wrapper {
  @apply flex w-full flex-col md:flex-row max-w-full items-stretch flex-wrap gap-5 overflow-hidden;

  &.inverted {
    @apply flex-col md:flex-row-reverse;
  }

  .extended-col {
    @apply flex-grow w-full;
    @media (min-width: 1024px) {
      max-width: calc(100% - 364px - 20px);
    }
  }

  .aside-col {
    @apply w-full flex-grow;
    @media (min-width: 1024px) {
      max-width: 364px;
      height: auto;
    }
  }
  &:not(.disable-ratio) {
    .aside-col {
      aspect-ratio: 364/270;
      height: min-content;
    }
  }

  &.two-sides {
    .aside-col {
      aspect-ratio: 364/230;
      height: min-content;
    }
    .extended-col {
      @media (min-width: 1024px) {
        max-width: calc(100% - calc(364px * 2) - calc(20px * 2));
      }
    }
  }
}
</style>
