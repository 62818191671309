<template>
  <div class="support-card">
    <div class="info">
      <div class="icon hidden md:block">
        <Icon name="medusa-icon-large" class="w-24 h-24 lg:w-40 lg:h-40" />
      </div>
      <div class="content">
        <h3>{{ title || "We’re here to help" }}</h3>
        <p class="mt-1">
          {{ text || "Have questions? Get them answered by a representative" }}
        </p>
        <a
          href="https://info.medusadistribution.com/contact-us"
          target="_blank"
          class="inline-block xl:hidden text-sm mt-3"
          @click="openChat"
        >
          {{ cta || "Talk to us" }}
        </a>
      </div>
    </div>
    <a
      href="https://info.medusadistribution.com/contact-us"
      target="_blank"
      class="hidden xl:inline-block text-base"
      @click="openChat"
    >
      {{ cta || "Talk to us" }}
    </a>
  </div>
</template>

<script>
export default {
  name: "SupportCard",
  props: {
    title: String,
    text: String,
    cta: String,
  },
  setup() {
    const openChat = (e) => {
      e.preventDefault();
      window.Intercom && Intercom("show");
    };

    return {
      openChat,
    };
  },
};
</script>

<style lang="scss" scoped>
.support-card {
  @apply flex items-center h-full px-6 py-12 md:px-8 md:py-5 lg:px-12 lg:py-8 bg-black;
  background: #3b3b3b;
  border-radius: 11px;

  h3 {
    @apply text-xl md:text-2xl font-semibold z-50 text-white relative;
  }

  p {
    @apply text-base md:text-lg font-medium text-white z-50 text-opacity-70 relative max-w-sm;
  }

  a {
    @apply font-medium bg-white text-black px-6 py-1.5 rounded;
  }

  .info {
    @apply flex items-center gap-12 flex-grow;
  }
}
</style>
