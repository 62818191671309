<template>
  <div class="lightning-order-info">
    <div class="image">
      <img src="/images/lorder.png" alt="Lightning Order" />
    </div>
    <div class="info">
      <Icon name="lightning" />
      <h3>Discover Lightning Order</h3>
      <p>Medusa helps vape shops source the top products and brands</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightningOrderInfo",
};
</script>

<style lang="scss" scoped>
.lightning-order-info {
  @apply flex flex-col items-stretch justify-between rounded-t-lg overflow-hidden w-full;
  background: #4b3677;

  .image {
    @apply w-full p-8 flex items-center justify-center flex-grow;
  }

  .info {
    @apply w-full flex flex-col relative px-12 py-8;
    background: #342356;

    svg {
      @apply absolute right-8 top-8 text-white w-6 h-6;
    }

    h3 {
      @apply text-3xl text-white font-bold mb-1;
    }

    p {
      @apply text-xl text-white text-opacity-60 font-medium max-w-2xl;
    }
  }
}
</style>
