<template>
  <div class="order-table">
    <slot name="header" />
    <table>
      <thead class="bg-white">
        <tr>
          <th scope="col" class="text-left">PRODUCT</th>
          <th scope="col" width="60" class="text-center">Qty</th>
          <th scope="col" width="100" class="text-right">Total</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr>
          <td>aasdasdas dasdasdaasdsd asdas;dkj asd askdjadslk a asdasdsdas asdasdasd</td>
          <td>asdas</td>
          <td>asdas</td>
        </tr> -->
        <tr v-for="(item, i) in orderItems" :key="i">
          <td>
            <div class="order-item">
              <img :src="item.image_url" alt="Image" />
              <div class="order-item-info">
                <p>{{ item.productName }}</p>
                <span>{{ item.optionLabel }}</span>
              </div>
            </div>
          </td>
          <td class="text-center">{{ item.qty }}</td>
          <td class="text-right hightlight">
            ${{ commaFormat((item.singlePrice * item.qty).toFixed(2)) }}
          </td>
        </tr>
      </tbody>
    </table>
    <button
      v-if="items && items.length > 5"
      class="block text-xs text-theme-primary px-5 py-2 focus:outline-none mb-2"
      @click="showAll = !showAll"
    >
      View {{ showAll ? "Less" : "All" }}
    </button>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import { computed, ref } from "vue";
import clone from "lodash/clone";

export default {
  name: "OrderItems",
  mixins: [Mymixin],
  props: {
    items: Object,
  },
  setup(props) {
    const showAll = ref(false);

    const orderItems = computed(() => {
      if (showAll.value || props.items.length < 6) {
        return props.items;
      }
      return clone(props.items).splice(0, 5);
    });

    return {
      showAll,
      orderItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  @apply bg-white;
  width: 100%;

  table {
    @apply min-w-full divide-y divide-gray-200;
    width: 100%;
    word-break: break-word;

    th {
      @apply px-4 py-1.5 text-xxs font-medium text-brand-body uppercase tracking-wider;

      &:not(:first-child) {
        @apply px-2 md:px-4;
      }
    }

    tbody {
      @apply bg-white text-xs text-brand-secondary font-medium;

      td {
        @apply px-3 py-4 font-normal;

        &.hightlight {
          @apply font-semibold;
        }

        .order-item {
          @apply flex items-center space-x-3 flex-shrink-0;
          img {
            @apply w-8 h-8 mr-1 object-contain object-center;
          }
          .order-item-info {
            @apply flex-grow;
            p {
              @apply text-xs leading-none text-brand-secondary font-semibold mb-0.5;
            }
            span {
              @apply text-xxs leading-none text-brand-gray font-normal;
            }
          }
        }
      }
    }
  }
}
.info-title {
  @apply flex items-center justify-between px-4 py-3;
  .title {
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg {
      @apply w-5 h-5;
    }
  }
  .price {
    @apply text-sm text-black font-semibold;
  }
}
</style>
