<template>
  <div class="flex items-center w-full justify-center h-full px-2">
    <button
      class="agile-arrow"
      v-if="clickedNext"
      @click="$refs.newarrivals.goToPrev()"
    >
      <ChevronLeftIcon />
    </button>
    <template v-if="isLoading">
      <VerticalProduct class="w-1/5" v-for="x in 5" :key="x" />
    </template>
    <VueAgile ref="newarrivals" :options="options" class="max-w-full" v-else>
      <a
        v-for="(collect, i) in data.slice(0, 10)"
        :key="collect.id"
        class="prod-item cursor-pointer"
        @click="gotToPage(collect, i)"
      >
        <div class="prod-image">
          <img :alt="collect.label" :src="getImage(collect)" />
        </div>
        <p>{{ collect.name }}</p>
        <span>{{ collect.brand }}</span>
      </a>
    </VueAgile>
    <button
      class="agile-arrow"
      v-if="!isLoading"
      @click="$refs.newarrivals.goToNext(), (clickedNext = true)"
    >
      <ChevronRightIcon />
    </button>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/solid";
import { ref } from "vue";
import VerticalProduct from "@/components/loaders/VerticalProduct.vue";
import { useRouter } from "vue-router";
import Segments from "@/modules/Segments";
import { useStore } from 'vuex';

const options = {
  navButtons: false,
  dots: false,
  infinite: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 7,
      },
    },
  ],
};

export default {
  name: "New Arrivals",
  props: {
    data: Object,
    isLoading: Boolean,
  },
  components: {
    VueAgile,
    ChevronRightIcon,
    ChevronLeftIcon,
    VerticalProduct,
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { ProductClicked } = Segments();

    const gotToPage = (item, pos) => {
      let data = {
        id: "new-arrivals",
        type: "home",
        price: Math.max(...item.variants.map(r => r.wholesale_price)).toFixed(2),
      }

      store.dispatch("setList", {
        list_id: data.id,
        list_type: data.type,
        position: pos+1,
      });

      ProductClicked(router, item, pos+1, data);
      router.push("/products/" + item.slug);
    }

    const getImage = (item) => {
      if(item.image_url == null){
        return item.variants[0]?.ImageThumbnailUrl;
      }
      return item.image_url;
    }
    const clickedNext = ref(false);

    return {
      gotToPage,
      options,
      clickedNext,
      getImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.prod-item {
  @apply flex flex-col items-center space-y-1.5 leading-none text-center;

  .prod-image {
    @apply flex items-center justify-center;

    img {
      object-fit: contain;
      object-position: center;
    }

    &:not(.custom) {
      @apply h-full;
      max-height: 105px;
    }

    img:not(.custom) {
      @apply block w-full h-auto max-h-full;
      max-height: 120px;
      max-width: 90%;
    }
  }
  p {
    @apply text-base font-semibold text-theme-secondary w-full leading-5 truncate;
  }
  span {
    @apply text-sm font-medium text-theme-body;
  }
}
</style>
